<template>
  <stand-out-block class="source-item result-item" :class="{'is-row': isDesktop}">
    <div class="content">
      <h4 class="title">
        <router-link class="details-link" :to="detailsRoute">
          {{ title }}
        </router-link>
      </h4>
      <record-type :type-name="recordTypeLabel"></record-type>

      <div class="mobile-image" v-if="!isDesktop && (imageSrc || isPhysical)">
        <router-link :to="detailsRoute">
          <image-physical-copy v-if="isPhysical"></image-physical-copy>
          <img :data-src="imageSrc" class="lazyload image" v-else />
        </router-link>
      </div>

      <div class="meta-info" v-if="item.collection_id">
        <div v-if="timePeriod">
          <span class="supplemental">Time Period: </span><span>{{ timePeriod }}</span>
        </div>
        <div>
          <span class="supplemental">Total Sources: </span
          ><span>{{ item.source_ids.length.toLocaleString('en') }}</span>
        </div>
        <div>
          <span class="supplemental">Total Indexed Records: </span
          ><span>{{ item.mentions_count.toLocaleString('en') }}</span>
        </div>
        <div>
          <span class="supplemental">Total Images: </span><span>{{ item.images_count.toLocaleString('en') }}</span>
        </div>
      </div>
      <div class="meta-info" v-else>
        <div v-if="item.publication_year">
          <span class="supplemental">Published: </span
          ><span
            >{{ item.publication_year
            }}<span v-if="item.publication_year_latest"> - {{ item.publication_year_latest }}</span></span
          >
        </div>
        <div v-if="indexedPersonsInfo">
          <span class="supplemental">Record Status: </span><span>{{ indexedPersonsInfo }}</span>
        </div>
        <div v-if="clansInfo">
          <span class="supplemental">Clans: </span><span>{{ clansInfo }}</span>
        </div>
        <div v-if="ancestralPlaceInfo">
          <span class="supplemental">Ancestral Place: </span><span>{{ ancestralPlaceInfo }}</span>
        </div>
      </div>
      <div class="view-button-container">
        <router-link :to="detailsRoute" class="view-button"
          ><view-icon :size="20" class="view-icon"></view-icon> {{ viewLinkLabel }}</router-link
        >
      </div>
    </div>

    <router-link :to="detailsRoute" class="desktop-image" v-if="isDesktop">
      <image-physical-copy v-if="isPhysical"></image-physical-copy>
      <img v-else-if="imageSrc" :data-src="imageSrc" class="lazyload image" />
      <div v-else class="image-placeholder"><image-icon class="image-icon" :size="70"></image-icon></div>
    </router-link>
  </stand-out-block>
</template>

<script>
import RecordType from '@common/elements/icons/RecordType';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import ImagePhysicalCopy from '@common/pages/searches/results/components/ImagePhysicalCopy';
import {sourceImageFastThumbnailLink} from '@common/utils/utils';
import {getCemeteryDetailRoute} from '@common/utils/utils.routes';
import ViewIcon from 'vue-material-design-icons/FolderMoveOutline';
import ImageIcon from 'vue-material-design-icons/ImageOff';

export default {
  props: {
    item: Object,
    meta: Object,
  },
  data() {
    return {
      imageHasError: !this.item.thumbnail_token,
    };
  },
  computed: {
    title() {
      const {title_en, title_ch, publication_year, collection_id} = this.item;
      let years = this.timePeriod;

      if (publication_year && !collection_id) {
        years = publication_year;
      }

      const title = `${title_en || ''}\n${title_ch || ''}`.trim();
      return years ? `${title}, ${years}` : title;
    },
    detailsRoute() {
      const type = this.item.type ? this.item.type[0] : '';
      if (this.item.collection_id) {
        return {name: 'collection-detail', params: {id: this.item.collection_id}};
      }
      if (type === 'zupu') {
        return {name: 'search-tool-zupu-detail', params: {zupuId: this.item.clan_pedigree_id}};
      }
      if (type === 'cemetery') {
        return getCemeteryDetailRoute(this.item.cemetery_id[0], '');
      }
      return {name: 'source-detail', params: {sourceId: this.item.object_id}};
    },
    isPhysical() {
      return this.item.record_format === 'physical';
    },
    indexedPersonsInfo() {
      if (this.isPhysical) {
        return 'Physical only';
      }
      return this.item.mentions_count ? `${this.item.mentions_count} persons indexed` : 'Unindexed';
    },
    clansInfo() {
      const clans = this.item.clans
        ? this.item.clans
            .map(clanId => {
              const clanObj =
                clanId && clanId !== 'none' ? this.meta.facets.clans.find(clan => clan.object_id === clanId) : null;
              return clanObj ? `${clanObj.pinyin} ${clanObj.name_hant || clanObj.name_ch}` : null;
            })
            .filter(clan => !!clan)
        : [];
      if (clans.length === 0) {
        return '';
      }
      if (clans.length === 1) {
        return clans[0];
      }
      if (clans.length > 1 && clans.length < 4) {
        return clans.slice(0, -1).join(', ') + ', and ' + clans.slice(-1);
      }
      const first = clans.slice(0, 3).join(', ');
      return first + ', and ' + (clans.length - 3) + ' more';
    },
    timePeriod() {
      return this.item.time_period_start || this.item.time_period_end
        ? `${this.item.time_period_start || '...'}-${this.item.time_period_end || '...'}`
        : '';
    },
    recordTypeLabel() {
      const type = this.item.type_display && this.item.type_display !== 'none' ? this.item.type_display : '';
      let country = this.item.country ? this.item.country.join(', ') : '';
      country = country === 'none' ? '' : country;
      return type && country ? `${country}, ${type}` : type || country;
    },
    ancestralPlaceInfo() {
      return this.item.ancestral_place_names ? this.item.ancestral_place_names.join(', ') : '';
    },
    isDesktop() {
      const width = this.$store.getters.windowWidthState;
      return width >= 1100 || (width >= this.$breakpoints.phablet && width <= this.$breakpoints.tablet);
    },
    imageSrc() {
      if (this.item.thumbnail_token) {
        return sourceImageFastThumbnailLink(this.item.thumbnail_token, 400);
      }
    },
    viewLinkLabel() {
      return this.item.collection_id ? 'View Collection' : 'View Source';
    },
  },
  methods: {
    setImageHasError() {
      this.imageHasError = true;
    },
  },
  components: {StandOutBlock, RecordType, ViewIcon, ImageIcon, ImagePhysicalCopy},
  name: 'SourceItem',
};
</script>

<style lang="scss" scoped>
@import 'styles/result-item';
.source-item {
  flex-direction: row-reverse;

  .desktop-image {
    margin-right: 30px;
  }

  .meta-info {
    margin-top: 20px;

    > div {
      margin-top: 5px;
    }
  }

  .view-button {
    font-weight: bold;
  }

  .one-line {
    display: inline-block; /* fallback */
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
}
</style>
