import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {
  TAB_ID_INDEXED_RECORDS,
  TAB_ID_FULL_TEXT,
  TAB_ID_SOURCES,
  PERSON_FACT_FIELDS,
  PERSON_RELATIVE_FIELDS,
  SEARCH_TAB_BY_DEFAULT_FORM,
} from '@common/utils/consts.search';
import {getRandomString} from '@common/utils/utils';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import sortBy from 'lodash/sortBy';

export default {
  init() {
    this.$scrollTo({x: 0});
    const queryParamsApplied = this.isQueryParamsApplied;
    if (!queryParamsApplied) {
      this.parseFiltersFromQuery();
    }
    if (
      !queryParamsApplied ||
      (!this.filtersExist(this.filters) && this.$route.query.results && isEmpty(this.resultsList) && !this.loading)
    ) {
      this.runSearchWithAppliedFilters(this.$route.query.page);
    }
  },
  handleSearchNotAllowed() {
    const filters = pickBy(this.filters);
    if (this.activeTab === TAB_ID_INDEXED_RECORDS && !this.filtersExist(filters)) {
      this.loading = false;
      this.searchRan = false;
      const cleanQuery = {category_id: filters.category_id, tab: this.activeTab};
      this.setRouteQuery(cleanQuery);
      return true;
    }
  },
  runSearchWithAppliedFilters(page, trackSearchType) {
    if (this.handleSearchNotAllowed()) {
      return;
    }
    this.loading = true;
    const filters = pickBy(this.filters);
    this.searchRan = true;
    filters.page = page || 1;
    this.runSearch(filters, trackSearchType);
  },
  onSubmit({tab} = {}, trackSearchType) {
    this.loading = true;
    this.activeTab = tab || this.activeTab;
    if (this.handleSearchNotAllowed()) {
      return;
    }
    let filters = pickBy(this.filters);
    filters = this.$route.query.page ? {...filters, page: 1} : filters;
    filters.tab = this.activeTab;
    filters.results = 'true';
    this.setRouteQuery(filters);
    this.runSearch(filters, trackSearchType);
  },
  onSubmitFromInitState(data) {
    this.onSubmit(data, 'new search');
  },
  onSubmitFromEditSearch(data) {
    this.onSubmit(data, 'edit search');
  },
  onValidationError() {
    if (this.showAdvancedSearch) {
      this.$scrollTo('.has-error', {offset: -150, force: true, cancelable: false});
    }
  },
  setRouteQuery(newQuery) {
    const {facts, relatives, ...otherQuery} = newQuery;
    if (facts) {
      Object.keys(facts).forEach(key => {
        otherQuery[key] = facts[key];
      });
    }
    if (relatives) {
      Object.keys(relatives).forEach(key => {
        otherQuery[key] = relatives[key];
      });
    }
    if (JSON.stringify(otherQuery) !== JSON.stringify(this.$route.query)) {
      this.$router.push({query: otherQuery});
    }
  },
  saveToRouteQuery(filters) {
    let query = {...this.$route.query, ...filters};
    this.setRouteQuery(query);
  },
  onSwitchPage(page) {
    this.saveToRouteQuery({page});
    this.runSearchWithAppliedFilters(page, 'switch page');
  },
  forceActivateTab(tab) {
    this.$refs.tabs ? this.$refs.tabs.$refs.tabs.activateTab(tab) : null;
    this.activeTab = tab;
  },
  onEditSearchClick() {
    this.isClearEditSearch = false;
    this.$modal.show('edit-search');
  },
  onClearSearchClick() {
    this.isClearEditSearch = true;
    this.$modal.show('edit-search');
  },
  onEditFiltersClick() {
    this.$modal.show('filters-mobile');
  },
  toggleAdvancedSearch() {
    AnalyticsAmplitudeHandler.trackAdvancedSearchClick(getRoutePageName(this.$route), this.advancedSearchTogglerText);
    this.showAdvancedSearch = !this.showAdvancedSearch;
    const queryValue = this.showAdvancedSearch ? 'true' : undefined;
    this.$router.replace({query: {...this.$route.query, advanced_search: queryValue}});
    if (this.$refs.tabs.$refs['indexed-records-form']) {
      this.$refs.tabs.$refs['indexed-records-form'].setIsAdvanced(this.showAdvancedSearch);
    }
    if (this.$refs.tabs.$refs['full-text-form']) {
      this.$refs.tabs.$refs['full-text-form'].setIsAdvanced(this.showAdvancedSearch);
    }
    if (this.$refs.tabs.$refs['sources-form']) {
      this.$refs.tabs.$refs['sources-form'].setIsAdvanced(this.showAdvancedSearch);
    }
  },
  getTitle(initText, collectionName, surname, firstName, recordType, sourceTypes, place) {
    const fullName = surname && firstName ? [surname, firstName].join(', ') : surname || firstName;
    const sourceType =
      sourceTypes && recordType ? sourceTypes.find(c => c.value == recordType || c.object_id == recordType) : null;
    const collection = sourceType ? sourceType.name : collectionName;
    const placeText = place && place.address_en ? ` of ${place.address_en}` : '';
    if (this.searchRan && fullName) {
      return `Search Results for "${fullName}" in ${collection}${placeText}`;
    }
    return this.searchRan ? `Search Results in ${collection}${placeText}` : initText;
  },
  mutateSourcesForm(data) {},
  getParsedQueryFiltersIndexed() {
    let autoPlace = this.$route.query.auto_place_id ? {id: this.$route.query.auto_place_id} : {};
    if (this.$route.query.auto_place_name && !this.$route.query.auto_place_id) {
      autoPlace.full_address_en = this.$route.query.auto_place_name;
      autoPlace.address_en = this.$route.query.auto_place_name;
    }
    return {
      first_name: this.$route.query.first_name,
      surname: this.$route.query.surname || null,
      auto_place: autoPlace,
      birth_location: this.$route.query.birth_location_id ? {id: this.$route.query.birth_location_id} : {},
      death_location: this.$route.query.death_location_id ? {id: this.$route.query.death_location_id} : {},
      residence_location: this.$route.query.residence_location ? {id: this.$route.query.residence_location} : {},
      birth_year: this.$route.query.birth_year || null,
      death_year: this.$route.query.death_year || null,
      category_id: this.$route.query.category_id,
      source_types: this.$route.query.source_types || null,
      gender: this.$route.query.gender || null,
      source_location: this.$route.query.country_id ? {id: this.$route.query.country_id} : {},
      source_id: this.$route.query.source_id || null,
      cemetery_id: this.$route.query.cemetery_id || null,
      clan_name: this.$route.query.clan_name,
      record_year: this.$route.query.record_year,
      has_images: this.$route.query.has_images,
      with_fuzzy_first_name: this.$route.query.with_fuzzy_first_name === 'true',
      with_fuzzy_surname: this.$route.query.with_fuzzy_surname === 'true',
      facts: this.getParsedFactsFromQuery(),
      relatives: this.getParsedRelativesFromQuery(),
      collection: this.$route.query.collection_id ? {id: this.$route.query.collection_id} : {},
    };
  },
  getParsedArrayFromQuery(prefix, allowFields) {
    let items = {};
    for (let [key, value] of Object.entries(this.$route.query)) {
      if (key.startsWith(prefix)) {
        // eslint-disable-next-line no-unused-vars
        const [item_number, field, ...ignore] = key.split(/_(.*)/s);
        if (allowFields.includes(field)) {
          items[item_number] = items[item_number] || {formId: getRandomString()};
          items[item_number][field] = value;
        }
      }
    }

    return Object.values(items);
  },
  getParsedFactsFromQuery() {
    return this.getParsedArrayFromQuery('fact', PERSON_FACT_FIELDS);
  },
  getParsedRelativesFromQuery() {
    return this.getParsedArrayFromQuery('relative', PERSON_RELATIVE_FIELDS);
  },
  getArrayFilters(items, prefix, allowFields) {
    if (!items) {
      return {};
    }
    let filters = {};
    let i = 1;
    for (let item of items) {
      for (let [key, value] of Object.entries(item)) {
        if (allowFields.includes(key) && value) {
          filters[`${prefix}${i}_${key}`] = value;
        }
      }
      i += 1;
    }
    return filters;
  },
  getFactsFilters(facts) {
    return this.getArrayFilters(facts, 'fact', PERSON_FACT_FIELDS);
  },
  getRelativesFilters(relatives) {
    return this.getArrayFilters(relatives, 'relative', PERSON_RELATIVE_FIELDS);
  },
  getParsedQueryFiltersSources() {
    return {
      q: this.$route.query.q || null,
      auto_place: this.$route.query.auto_place_id ? {id: this.$route.query.auto_place_id} : {},
      country: this.$route.query.country,
      clan_name: this.$route.query.clan_name,
      ancestral_place: this.$route.query.ancestral_place_id ? {id: this.$route.query.ancestral_place_id} : {},
      ancestral_level_1: this.$route.query.ancestral_level_1_ids ? {id: this.$route.query.ancestral_level_1_ids} : {},
      ancestral_level_2: this.$route.query.ancestral_level_2_ids ? {id: this.$route.query.ancestral_level_2_ids} : {},
      ancestral_level_3: this.$route.query.ancestral_level_3_ids ? {id: this.$route.query.ancestral_level_3_ids} : {},
      publication_year: this.$route.query.publication_year,
      category_id: this.$route.query.category_id,
      source_type: this.$route.query.source_type,
      record_format: this.$route.query.record_format,
      has_ocr: this.$route.query.has_ocr,
      has_images: this.$route.query.has_images,
      collection: this.$route.query.collection_id ? {id: this.$route.query.collection_id} : {},
    };
  },
  parseFiltersFromQuerySources() {
    this.mutateSourcesForm(this.getParsedQueryFiltersSources());
    if (this.$route.query.auto_place_id) {
      this.network.place
        .getPlacesDetail({id: this.$route.query.auto_place_id, fields: 'full_address_en,address_en,id,parents'})
        .then(res => {
          this.mutateSourcesForm({auto_place: res});
        });
    }
    this.fetchCollectionObject(this.mutateSourcesForm);
    this.fetchAncestralPlaceObjects(this.mutateSourcesForm);
  },
  mutateFullTextForm(data) {},
  mutateIndexedForm(data) {},
  getParsedQueryFiltersFullText() {
    return {
      q: this.$route.query.q || null,
      last_name: this.$route.query.last_name || null,
      clan_name: this.$route.query.clan_name || null,
      ancestral_place: this.$route.query.ancestral_place_id ? {id: this.$route.query.ancestral_place_id} : {},
      ancestral_level_1: this.$route.query.ancestral_level_1_ids ? {id: this.$route.query.ancestral_level_1_ids} : {},
      ancestral_level_2: this.$route.query.ancestral_level_2_ids ? {id: this.$route.query.ancestral_level_2_ids} : {},
      ancestral_level_3: this.$route.query.ancestral_level_3_ids ? {id: this.$route.query.ancestral_level_3_ids} : {},
      country: this.$route.query.country || null,
      category_id: this.$route.query.category_id,
      source_type: this.$route.query.source_type || null,
      collection: this.$route.query.collection_id ? {id: this.$route.query.collection_id} : {},
      source_id: this.$route.query.source_id || null,
    };
  },
  parseFiltersFromQueryFullText() {
    this.mutateFullTextForm(this.getParsedQueryFiltersFullText());
    this.fetchCollectionObject(this.mutateFullTextForm);
    this.fetchAncestralPlaceObjects(this.mutateFullTextForm);
  },
  fetchAncestralPlaceObjects(mutation) {
    const items = ['ancestral_level_1_ids', 'ancestral_level_2_ids', 'ancestral_level_3_ids'];
    const formNamesMapping = {
      ancestral_level_1_ids: 'ancestral_level_1',
      ancestral_level_2_ids: 'ancestral_level_2',
      ancestral_level_3_ids: 'ancestral_level_3',
    };
    const method = name => {
      const queryValue = this.$route.query[name];
      const formName = formNamesMapping[name];
      if (queryValue && queryValue !== 'none') {
        this.network.place
          .getPlacesDetail({id: queryValue, fields: 'full_address_en,address_en,id,parents'})
          .then(res => {
            mutation({[formName]: res});
          });
      }
    };
    for (let queryName of items) {
      method(queryName);
    }

    if (this.$route.query.ancestral_place_id && this.$route.query.ancestral_place_id !== 'none') {
      this.network.place
        .getPlacesDetail({
          id: this.$route.query.ancestral_place_id,
          fields: 'full_address_en,address_en,id,parents,level',
        })
        .then(res => {
          const hierarchy = res.parents ? sortBy([res, ...res.parents], 'level') : [];
          let ancestral_level_1 = hierarchy.length > 1 ? hierarchy[1] : null;
          ancestral_level_1 = res.id == process.env.VUE_APP_TAIWAN_ID ? res : ancestral_level_1;
          const ancestral_level_2 = hierarchy.length > 2 ? hierarchy[2] : null;
          const ancestral_level_3 = hierarchy.length > 3 ? hierarchy[3] : null;
          mutation({ancestral_place: res, ancestral_level_1, ancestral_level_2, ancestral_level_3});
        });
    }
  },
  fetchCollectionObject(mutation) {
    if (this.$route.query.collection_id) {
      this.network.source
        .getCollectionDetails({id: this.$route.query.collection_id, fields: 'id,title_en,title_ch,name'})
        .then(res => {
          mutation({collection: res});
        });
    }
  },
  fetchFactsRelatedObjects(facts) {
    for (let fact of facts) {
      if (fact.place_id) {
        this.network.place
          .getPlacesDetail({id: fact.place_id, fields: 'full_address_en,address_en,id,parents'})
          .then(res => {
            if (this.activeForm.facts) {
              const facts = [...this.activeForm.facts];
              const index = facts.findIndex(f => fact.formId === f.formId);
              const newFact = {...facts[index], place: res};
              facts.splice(index, 1, newFact);
              this.mutateIndexedForm({facts: facts});
            }
          });
      }
      if (fact.cemetery_id) {
        this.network.cemetery.getDetailed({id: fact.cemetery_id, fields: 'name'}).then(res => {
          if (this.activeForm.facts) {
            const facts = [...this.activeForm.facts];
            const index = facts.findIndex(f => fact.formId === f.formId);
            const newFact = {...facts[index], cemetery: res};
            facts.splice(index, 1, newFact);
            this.mutateIndexedForm({facts: facts});
          }
        });
      }
    }
  },
  filtersExist(filters) {
    let value = null;
    return Object.keys(filters).some(key => {
      if (key === 'category_id') {
        return false; // don't consider category_id a filter to show initial form state
      }
      value = filters[key];
      if (value && typeof value === 'object') {
        return Object.values(value).some(v => !!v);
      }
      return !!value;
    });
  },
  filtersInQuery(activeTab) {
    if (!activeTab) {
      return false;
    }
    /* show init form state, instead of loading on page open with only utm tracking query params, */
    if (this.$route.query.results) {
      return true;
    }
    const mapping = {
      [TAB_ID_INDEXED_RECORDS]: this.getParsedQueryFiltersIndexed(),
      [TAB_ID_FULL_TEXT]: this.getParsedQueryFiltersFullText(),
      [TAB_ID_SOURCES]: this.getParsedQueryFiltersSources(),
    };
    const filters = mapping[activeTab];
    return this.filtersExist(filters);
  },
  onFilterSelectIndexed({typeName, value, deselectFields}) {
    const payload = this.getPayloadForFiltersCommit(typeName, value, deselectFields);
    this.mutateIndexedForm(payload);
    this.onSubmit({}, 'results filter');
  },
  onInitStateActivateTab({tabId, fromTabId}) {
    this.saveToRouteQuery({tab: tabId});
  },
  getAncestralPlacesFilters(form) {
    return {
      ancestral_level_1_ids: form.ancestral_level_1 ? form.ancestral_level_1.id : null,
      ancestral_level_2_ids: form.ancestral_level_2 ? form.ancestral_level_2.id : null,
      ancestral_level_3_ids: form.ancestral_level_3 ? form.ancestral_level_3.id : null,
    };
  },
  getPayloadForFiltersCommit(typeName, value, deselectFields) {
    const payload = typeName ? {[typeName]: value} : {};
    if (deselectFields) {
      deselectFields.forEach(name => {
        payload[name] = null;
      });
    }
    return payload;
  },
  fetchAndSetInitialSearchPageState(categoryId) {
    if (categoryId && categoryId !== this.searchCategoryState.id) {
      return this.$store
        .dispatch('fetchSearchCategoryAction', {id: categoryId})
        .then(response => {
          const categoryDefaultTab = SEARCH_TAB_BY_DEFAULT_FORM[response.default_form];
          this.activeTab = this.activeTab || categoryDefaultTab;
          if (
            (this.activeTab === TAB_ID_INDEXED_RECORDS && !response.has_indexed_form) ||
            (this.activeTab === TAB_ID_FULL_TEXT && !response.has_full_text_form)
          ) {
            this.activeTab = categoryDefaultTab;
          }
        })
        .catch(() => {
          this.$router.replace({query: {}});
        });
    } else {
      // to re-render the form in case user goes from one category to the all records and vice versa.
      this.forceShowInitialLoading = true;
      this.$nextTick(() => {
        this.forceShowInitialLoading = false;
      });
    }
    this.activeTab = this.activeTab || TAB_ID_INDEXED_RECORDS;
  },
};
