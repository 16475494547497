<template>
  <div class="full-text-form advanced-form unified-search-form" v-if="isAdvanced">
    <div class="filter form-item inline" v-if="showLangSelector">
      <label class="label">Search in: </label>
      <radio-select
        :options="languageOptions"
        :selected="lang"
        name="language-radio-full-text"
        @change="onLanguageChange"
      ></radio-select>
    </div>

    <div class="filter-group form-item">
      <div class="filter">
        <label class="input-label">First Name or Keyword</label>
        <input
          v-capitalize
          :value="formData.q"
          :placeholder="firstNamePlaceholder"
          :class="{'has-error': errors.q}"
          class="input"
          @input="onInputChange('q', $event)"
          @keyup.enter="onInputEnter"
        />
      </div>
      <div class="filter">
        <label class="label input-label">Last Name (Optional)</label>
        <input
          v-capitalize
          v-show="enLangSelected"
          :value="formData.last_name"
          :placeholder="lastNamePlaceholder"
          :class="{'has-error': errors.last_name}"
          class="input"
          @input="onInputChange('last_name', $event)"
          @keyup.enter="onInputEnter"
        />
        <surname-autocomplete-select
          v-show="!enLangSelected"
          :placeholder="lastNamePlaceholder"
          :value="formData.last_name"
          :has-error="errors.last_name"
          @change="onSurnameChange"
        ></surname-autocomplete-select>
      </div>
    </div>
    <div v-if="errors.q" class="error">{{ errors.q }}</div>

    <div class="section-heading text-lg">Source Filters</div>

    <div class="filter form-item">
      <label for="source_clan" class="input-label">
        <mcr-wiki-glossary-link class="help-text" naturalId="last-name-filter" :is-help-text="true">
          Source Clan
        </mcr-wiki-glossary-link>
      </label>
      <surname-input
        :show-default-label="true"
        :value="surnameInputValue"
        :allow-spelling-select="true"
        @select="onSourceClanChange"
      ></surname-input>
    </div>

    <div class="filter form-item">
      <label for="ancestral_place" class="input-label">
        <mcr-wiki-glossary-link naturalId="ancestral-place-filter" :is-help-text="true">
          Source Ancestral Place
        </mcr-wiki-glossary-link>
      </label>
      <place-input
        label=""
        :value="formData.ancestral_place || {}"
        :show-default-options="false"
        :only-ancestral-places="true"
        :error="errors.ancestral_place"
        multiselect-classes="bordered"
        @select="onAncestralPlaceSelect"
      ></place-input>
    </div>

    <div class="filter form-item">
      <label for="location" class="input-label">Publication Location </label>
      <single-option-select
        id="location"
        placeholder="All Locations"
        value-field="object_id"
        :value="formData.country"
        :options="publicationCountriesOptions"
        @select="onValueChange('country', $event)"
      ></single-option-select>
    </div>

    <div class="filter form-item">
      <label for="source-types" class="input-label">Record Type</label>
      <single-option-select
        id="source-types"
        placeholder="All Record Types"
        :value="formData.source_type"
        value-field="object_id"
        :options="recordTypeOptions"
        @select="onValueChange('source_type', $event)"
      ></single-option-select>
    </div>

    <div class="filter form-item">
      <label for="categories" class="input-label">Category</label>
      <single-option-select
        id="categories"
        placeholder="All Categories"
        value-field="id"
        :value="formData.category_id"
        :options="categoriesOptions"
        @select="onValueChange('category_id', $event)"
      ></single-option-select>
    </div>

    <div class="button-container">
      <mcr-button v-if="showCancel" class="white" @click="$emit('cancel')">Cancel</mcr-button>
      <mcr-button class="search-button" @click="onSearchClick">
        <search-icon class="search-icon" :size="20"></search-icon>
        <span>Search</span>
      </mcr-button>
    </div>
  </div>
  <div class="full-text-form simple-form unified-search-form" v-else>
    <div class="filter form-item inline" v-if="showLangSelector">
      <label class="label">Search in: </label>
      <radio-select
        :options="languageOptions"
        :selected="lang"
        name="language-radio-full-text"
        @change="onLanguageChange"
      ></radio-select>
    </div>
    <div class="filter-group align-bottom">
      <div class="filter">
        <label class="input-label">First Name or Keyword</label>
        <input
          v-capitalize
          :value="formData.q"
          :placeholder="firstNamePlaceholder"
          :class="{'has-error': errors.q}"
          class="input"
          @input="onInputChange('q', $event)"
          @keyup.enter="onInputEnter"
        />
      </div>
      <div class="filter">
        <label class="input-label">Last Name (Optional)</label>
        <input
          v-capitalize
          v-show="enLangSelected"
          :value="formData.last_name"
          :placeholder="lastNamePlaceholder"
          :class="{'has-error': errors.last_name}"
          class="input"
          @input="onInputChange('last_name', $event)"
          @keyup.enter="onInputEnter"
        />
        <surname-autocomplete-select
          v-show="!enLangSelected"
          :placeholder="lastNamePlaceholder"
          :value="formData.last_name"
          :has-error="errors.last_name"
          @change="onSurnameChange"
        ></surname-autocomplete-select>
      </div>
      <mcr-button class="search-button" @click="onSearchClick">
        <search-icon class="search-icon" :size="20"></search-icon>
        <span>Search</span>
      </mcr-button>
    </div>

    <div v-if="errors.q" class="error">{{ errors.q }}</div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import SingleOptionSelect from '@common/elements/filters/SingleOptionSelect';
import RadioSelect from '@common/elements/filters/radioSelect';
import PlaceInput from '@common/elements/inputs/PlaceInput';
import SurnameAutocompleteSelect from '@common/elements/inputs/SurnameAutocompleteSelect';
import SurnameInput from '@common/elements/inputs/SurnameInput';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {getRoutePageIdentifier, getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {sortBy} from 'lodash';
import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';
import SearchIcon from 'vue-material-design-icons/Magnify';
import {mapGetters} from 'vuex';

const mcrWikiGlossaryLink = () => import('@common/elements/glossary/mcrWikiGlossaryLink');

const EN = 'en';
const CN = 'cn';

export default {
  props: {
    initAdvanced: {type: Boolean, default: false},
    initLang: {type: String, default: EN}, // en or cn
    showCancel: {type: Boolean, default: false},
    showLangSelector: {type: Boolean, default: true},
    clearForm: {type: Boolean, default: false},
  },
  data() {
    return {
      formData: {},
      lang: this.initLang,
      errors: {},
      isAdvanced: this.initAdvanced,
    };
  },
  created() {
    this.formData = this.getInitialFormData();
    this.initSwitchLang();
  },
  computed: {
    ...mapGetters(['sourcesTextSearchFormState', 'sourcesTextSearchOptionsState', 'searchAllRecordsOptionsState']),
    languageOptions() {
      return [
        {id: EN, text: 'English'},
        {id: CN, text: 'Chinese 中文'},
      ];
    },
    firstNamePlaceholder() {
      const mapping = {[EN]: 'Wing Joe', [CN]: 'eg. 添福'};
      return mapping[this.lang];
    },
    lastNamePlaceholder() {
      const mapping = {[EN]: 'Li, Chen, or Wang', [CN]: '陳, 黄, 劉'};
      return mapping[this.lang];
    },
    enLangSelected() {
      return this.lang === EN;
    },
    publicationCountriesOptions() {
      return this.sourcesTextSearchOptionsState.country
        ? sortBy(this.sourcesTextSearchOptionsState.country, 'name')
        : [];
    },
    recordTypeOptions() {
      return this.sourcesTextSearchOptionsState.source_type
        ? [...this.sourcesTextSearchOptionsState.source_type].sort((a, b) => a.name.localeCompare(b.name))
        : [];
    },
    categoriesOptions() {
      return this.searchAllRecordsOptionsState.categories
        ? [...this.searchAllRecordsOptionsState.categories].sort((a, b) => a.priority - b.priority)
        : [];
    },
    surnameInputValue() {
      const options = this.sourcesTextSearchOptionsState.clans || [];
      const clan = this.formData.clan_name
        ? options.find(clan =>
            ['name_ch', 'name_hant', 'object_id'].some(field => clan[field] === this.formData.clan_name)
          )
        : null;
      return clan ? clan : {name_ch: this.formData.clan_name};
    },
  },
  methods: {
    getInitialFormData() {
      let initFormData = {...this.sourcesTextSearchFormState};
      if (this.clearForm) {
        Object.keys(initFormData).forEach(key => {
          if (key !== 'category_id') {
            initFormData[key] = null;
          }
        });
      }
      return initFormData;
    },
    initSwitchLang() {
      // used by FormTabsBlockContainer
    },
    onSearchClick() {
      const isValid = this.validateFilters();
      if (isValid) {
        this.runSearch();
      }
    },
    runSearch() {
      this.$store.commit('mutateSourcesTextSearchFormState', this.formData);
      this.$emit('submit');
    },
    validateFilters() {
      this.errors = {};
      if (!this.formData.q) {
        this.errors.q = 'This field is required';
      }
      const isValid = isEmpty(this.errors);
      if (!isValid) {
        this.$nextTick(() => {
          this.$emit('validation-error', this.errors);
        });
      }
      return isValid;
    },
    onLanguageChange(lang) {
      this.lang = lang;
      this.errors.q = false;
      this.errors.last_name = false;
      AnalyticsAmplitudeHandler.trackClickLanguageSelectorEvent(
        getRoutePageName(this.$route),
        getRoutePageIdentifier(this.$route),
        'full text',
        lang
      );
    },
    onInputChange(fieldName, event) {
      this.onValueChange(fieldName, event.target.value.trim());
    },
    onValueChange(fieldName, fieldValue) {
      this.$set(this.formData, fieldName, fieldValue);
    },
    onSurnameChange(value) {
      this.onValueChange('last_name', upperFirst(value));
    },
    onSourceClanChange(value) {
      this.onValueChange('clan_name', value.name_hant || value.name_ch);
    },
    onAncestralPlaceSelect(value) {
      this.onValueChange('ancestral_place', value);
      const hierarchy = value.parents ? sortBy([value, ...value.parents], 'level') : [];
      let ancestral_level_1 = hierarchy.length > 1 ? hierarchy[1] : null;
      ancestral_level_1 = value.id == process.env.VUE_APP_TAIWAN_ID ? value : ancestral_level_1;
      const ancestral_level_2 = hierarchy.length > 2 ? hierarchy[2] : null;
      const ancestral_level_3 = hierarchy.length > 3 ? hierarchy[3] : null;
      this.onValueChange('ancestral_level_1', ancestral_level_1);
      this.onValueChange('ancestral_level_2', ancestral_level_2);
      this.onValueChange('ancestral_level_3', ancestral_level_3);
    },
    onInputEnter() {
      this.onSearchClick();
    },
    setIsAdvanced(value) {
      this.isAdvanced = value;
    },
    mutateFormData(newObject) {
      Object.assign(this.formData, newObject);
    },
  },
  components: {
    SearchIcon,
    PlaceInput,
    SurnameInput,
    RadioSelect,
    McrButton,
    SurnameAutocompleteSelect,
    SingleOptionSelect,
    mcrWikiGlossaryLink,
  },
};
</script>

<style lang="scss" scoped>
@import '~@common/pages/searches/styles/form.scss';
</style>
