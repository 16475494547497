<template>
  <div class="sticky-edit-buttons mobile-filter-buttons" id="mobile-filter-buttons">
    <mcr-button id="mobile-edit-button" class="mobile-edit-button dark-grey" @click="$emit('click-edit-search')">
      <pencil-icon :size="30" class="edit-icon" /><span>{{ editSearchBtnLabel }}</span>
    </mcr-button>

    <div class="edit-filters-wrapper" v-if="showFilters" :class="{applied: appliedFiltersCount}">
      <div class="filters-count" v-if="appliedFiltersCount" @click="$emit('click-filters')">
        {{ appliedFiltersCount }}
      </div>
      <mcr-button id="mobile-filters-button" class="mobile-edit-button dark-grey" @click="$emit('click-filters')">
        <filter-icon :size="30" class="edit-icon" /><span>{{ editFiltersBtnLabel }}</span>
      </mcr-button>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import throttle from 'lodash/throttle';
import FilterIcon from 'vue-material-design-icons/FilterVariant';
import PencilIcon from 'vue-material-design-icons/Pencil';

export default {
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  props: {
    showFilters: Boolean,
    appliedFiltersCount: Number,
  },
  data() {
    return {
      editSearchBtnLabel: 'Edit Search',
      editFiltersBtnLabel: 'Edit Filters',
      pastScroll: false,
    };
  },
  methods: {
    handleScroll: throttle(function () {
      const newPastScroll = window.scrollY > 180;
      if (newPastScroll !== this.pastScroll) {
        const container = document.getElementById('mobile-filter-buttons');

        const width = this.showFilters ? 130 : 60;
        container.style.maxWidth = newPastScroll ? `${width}px` : '100%';

        if (newPastScroll) {
          container.classList.add('scrolled');
        } else {
          container.classList.remove('scrolled');
        }
      }
      this.pastScroll = newPastScroll;
    }, 200),
  },
  components: {PencilIcon, McrButton, FilterIcon},
  name: 'StickyEditButtons',
};
</script>

<style lang="scss" scoped>
.mobile-filter-buttons {
  justify-content: center;
  position: sticky;
  position: -webkit-sticky;
  top: 75px;
  display: flex;
  max-width: 100%;
  transition: all 0.5s ease;
  left: 95%;
  margin-bottom: 20px;
  z-index: 1;

  &.scrolled {
    margin-right: -50px;

    .mobile-edit-button {
      width: 48px;
    }

    .filters-count {
      position: absolute;
      font-size: 10px;
      line-height: 16px;
      width: 16px;
      height: 16px;
      top: -4px;
      right: 2px;
    }

    @media only screen and (max-width: $breakpoint-phablet) {
      margin-right: -20px;
    }
    @media only screen and (max-width: $breakpoint-mobile-se) {
      margin-right: -15px;
    }
  }

  &:not(.scrolled) .edit-filters-wrapper.applied .mobile-edit-button {
    padding-right: 22px;
  }

  .edit-filters-wrapper {
    position: relative;
  }

  .filters-count {
    position: absolute;
    right: 20px;
    top: 12px;

    text-align: center;
    font-weight: bold;
    border-radius: 24px;
    font-size: 16px;
    line-height: 24px;
    width: 24px;
    height: 24px;
    background-color: $off-white;
    color: $mcr-dark-grey;
    text-shadow: none;
    transition: all 0.2s ease;
  }
}

.mobile-edit-button {
  transition: all 0.5s ease;
  overflow: hidden;
  white-space: nowrap;
  text-align: start;
  justify-content: flex-start;
  min-width: 0;
  margin-right: 10px;

  width: 156px;
  height: 48px;

  &::v-deep .mcr-button-label {
    align-items: center;
    padding: 9px;
  }

}
</style>
