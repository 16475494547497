<template>
  <div class="filters-full-text">
    <div v-if="noFilters" class="supplemental">No filters to apply.</div>
    <filter-item
      label="All Categories"
      :options="categoriesOptions"
      :cut-count="filterCutCount"
      :hide-header-count="true"
      @select="selectTypeCategory"
      @deselect="deSelectTypeCategory"
    ></filter-item>

    <filter-item
      label="All Source Locations"
      :options="countryOptions"
      :cut-count="filterCutCount"
      @select="selectType($event.object_id, 'country')"
      @deselect="deSelectType('country')"
    ></filter-item>

    <filter-item
      label="All Ancestral Places"
      :options="ancestralPlacesOptions"
      :cut-count="filterCutCount"
      :force-cut="true"
      @select="selectTypeAncestralPlace"
      @deselect="deSelectTypeAncestralPlace"
    ></filter-item>

    <filter-item
      label="All Chinese Surnames"
      :options="clansOptions"
      :cut-count="filterCutCount"
      :force-cut="true"
      @select="selectType($event.name_hant || $event.name_ch || $event.object_id, 'clan_name')"
      @deselect="deSelectType('clan_name')"
    ></filter-item>

    <filter-item
      label="All Indexing States"
      :options="formatOptions"
      :cut-count="filterCutCount"
      @select="selectType($event.object_id, 'record_format')"
      @deselect="deSelectType('record_format')"
    ></filter-item>

    <filter-item
      label="All Image Availabilities"
      :options="hasImagesOptions"
      :total-count="facets.has_images && facets.has_images.total_count"
      :cut-count="100"
      @select="selectType($event.object_id, 'has_images')"
      @deselect="deSelectType('has_images')"
    ></filter-item>

    <filter-item
      label="Full Text Available"
      :options="hasOcrOptions"
      :cut-count="filterCutCount"
      @select="selectType($event.object_id, 'has_ocr')"
      @deselect="deSelectType('has_ocr')"
    ></filter-item>
  </div>
</template>

<script>
import {isChineseText} from '@common/utils/utils';

import FilterItem from './FilterItem';
import commonFiltersComputed from './commonFiltersComputed';
import commonFiltersMethods from './commonFiltersMethods';

export default {
  props: {
    facets: Object,
    noneValue: String,
    sourceTypes: Array,
    formData: Object,
    totalCount: Number,
  },
  data() {
    return {
      filterCutCount: 5,
      sourceTypeTypeName: 'source_type',
    };
  },
  computed: {
    ...commonFiltersComputed,
    countDisplay() {
      return this.totalCount >= 10000 ? '10000+' : this.totalCount;
    },
    countryOptions() {
      if (!this.facets.country) {
        return [];
      }
      let options = this.facets.country;
      const formCountry = this.formData.country;
      if (formCountry && !options.find(item => item.object_id === formCountry)) {
        options = [{name: formCountry, object_id: formCountry, count: 0}, ...options];
      }
      return this.normalizeOptions(options, 'country');
    },
    ancestralPlacesOptions() {
      if (!this.facets.ancestral_level_1_id) {
        return [];
      }
      const selectedLevel1 = this.formData.ancestral_level_1 ? this.formData.ancestral_level_1.id : null;
      const selectedLevel2 = this.formData.ancestral_level_2 ? this.formData.ancestral_level_2.id : null;
      const selectedLevel3 = this.formData.ancestral_level_3 ? this.formData.ancestral_level_3.id : null;
      const ancestralPlaceId = this.formData.ancestral_place ? this.formData.ancestral_place.id : null;
      const ancestralPlaceItem = ancestralPlaceId
        ? {
            isSelected: true,
            name: this.formData.ancestral_place.address_en,
            object_id: ancestralPlaceId,
            count: this.countDisplay,
            ignoreCount: true,
            selectField,
            deselectFields,
          }
        : null;
      let selectedLevel1Present = false;
      let selectedLevel2Present = false;
      let selectedLevel3Present = false;
      let selectField = 'ancestral_level_3';
      let deselectFields = ['ancestral_place'];
      let level4places =
        ancestralPlaceId && selectedLevel3 && ancestralPlaceId !== selectedLevel3 ? [ancestralPlaceItem] : [];
      let level3places = this.facets.ancestral_level_3_id.map(item => {
        const selected = item.object_id == selectedLevel3;
        selectedLevel3Present = selectedLevel3Present || selected;
        return {
          ...item,
          isSelected: selected && !level4places.length,
          children: selected ? level4places : [],
          selectField,
          deselectFields,
        };
      });
      level3places = this.normalizeOptions(level3places, 'ancestral_level_3_id');
      if (!selectedLevel3Present && selectedLevel3) {
        level3places.unshift({
          isSelected: true,
          name: this.formData.ancestral_level_3.address_en,
          object_id: selectedLevel3,
          count: 0,
          children: level4places,
          selectField,
          deselectFields,
        });
      }
      selectField = 'ancestral_level_2';
      deselectFields = ['ancestral_level_3', 'ancestral_place'];
      let level2places = this.facets.ancestral_level_2_id.map(item => {
        const selected = item.object_id == selectedLevel2;
        selectedLevel2Present = selectedLevel2Present || selected;
        return {
          ...item,
          isSelected: selected && !selectedLevel3,
          children: selected ? level3places : [],
          selectField,
          deselectFields,
        };
      });
      level2places = this.normalizeOptions(level2places, 'ancestral_level_2_id');
      if (!selectedLevel2Present && selectedLevel2) {
        level2places.unshift({
          isSelected: true,
          name: this.formData.ancestral_level_2.address_en,
          object_id: selectedLevel2,
          count: 0,
          children: level3places,
          selectField,
          deselectFields,
        });
      }
      selectField = 'ancestral_level_1';
      deselectFields = ['ancestral_level_3', 'ancestral_level_2', 'ancestral_place'];
      let level1places = this.facets.ancestral_level_1_id.map(item => {
        const selected = item.object_id == selectedLevel1;
        selectedLevel1Present = selectedLevel1Present || selected;
        return {
          ...item,
          isSelected: selected && !selectedLevel2,
          children: selected ? level2places : [],
          selectField,
          deselectFields,
        };
      });
      level1places = this.normalizeOptions(level1places, 'ancestral_level_1_id');
      if (!selectedLevel1Present && selectedLevel1) {
        level1places.unshift({
          isSelected: true,
          name: this.formData.ancestral_level_1.address_en,
          object_id: selectedLevel1,
          count: 0,
          children: level2places,
          selectField,
          deselectFields,
        });
      }
      if (
        ancestralPlaceId &&
        !level4places.length &&
        ancestralPlaceId !== selectedLevel1 &&
        ancestralPlaceId !== selectedLevel2 &&
        ancestralPlaceId !== selectedLevel3
      ) {
        const arr = level2places.length ? level2places : level1places;
        arr.unshift(ancestralPlaceItem);
      }
      return level1places;
    },
    clansOptions() {
      if (!this.facets.clans) {
        return [];
      }
      let options = this.facets.clans.map(clan => {
        const nameCh =
          clan.name_hant && clan.name_ch !== clan.name_hant ? `${clan.name_ch} / ${clan.name_hant}` : clan.name_ch;
        const name = [clan.pinyin || '', nameCh].join(' ').trim() || clan.name;
        return {...clan, name};
      });
      const formClanName = this.formData.clan_name;
      if (formClanName && !options.find(item => this.isTypeItemClanNameSelected(item, formClanName))) {
        const nameDisplay = isChineseText(formClanName) ? formClanName : `${formClanName} (All Translations)`;
        options = [{name_ch: formClanName, name: nameDisplay, count: this.countDisplay, ignoreCount: true}, ...options];
      }
      return this.normalizeOptions(options, 'clan_name', 'name_ch', 'count', 'desc');
    },
    formatOptions() {
      if (!this.facets.record_format) {
        return [];
      }

      return this.facets.record_format.map(item => {
        const isSelected = this.isTypeItemSelected(item, 'record_format');
        return {...item, name: item.name, isSelected};
      });
    },
    hasOcrOptions() {
      if (!this.facets.has_ocr) {
        return [];
      }
      const mapping = {true: 'Yes', false: 'No'};
      let options = this.facets.has_ocr.map(item => {
        const isSelected = this.isTypeItemSelected(item, 'has_ocr');
        return {...item, name: mapping[item.object_id], isSelected};
      });
      options.sort((a, b) => (a.object_id === 'true' ? -1 : 1));
      return options;
    },
    hasImagesOptions() {
      if (!this.facets.has_images) {
        return [];
      }
      const mapping = {true: 'Images Available', false: 'No images available'};
      let options = this.facets.has_images;
      const formData = this.formData.has_images;
      if (formData && !options.find(item => item.object_id === formData)) {
        options = [{name: mapping[formData], object_id: formData, count: 0}, ...options];
      }
      options.sort(item => (item.object_id === 'true' ? -1 : 1));
      return options.map(item => {
        return {...item, name: mapping[item.object_id], isSelected: this.isTypeItemSelected(item, 'has_images')};
      });
    },
    noneName() {
      return 'None';
    },
    noFilters() {
      return (
        !this.countryOptions.length &&
        !this.categoriesOptions.length &&
        !this.ancestralPlacesOptions.length &&
        !this.clansOptions.length &&
        !this.formatOptions.length &&
        !this.hasOcrOptions.length &&
        !this.hasImagesOptions.length
      );
    },
  },
  methods: {
    ...commonFiltersMethods,
    isTypeItemSelected(item, typeName) {
      const mapping = {
        clan_name: this.isTypeItemClanNameSelected,
        ancestral_place: this.isTypeItemAncestralPlaceSelected,
        category_id: this.isTypeItemByIdSelected,
      };
      const method = mapping[typeName];
      const selectedValue = this.formData[typeName];
      return method ? method(item, selectedValue) : item.object_id === selectedValue;
    },
    isTypeItemClanNameSelected(item, selectedValue) {
      if (!selectedValue) {
        return false;
      }
      return ['name_ch', 'name_hant', 'object_id'].some(field => item[field] === selectedValue);
    },
    isTypeItemByIdSelected(item, selectedValue) {
      return selectedValue ? selectedValue === item.id : false;
    },
    isTypeItemAncestralPlaceSelected(item, selectedValue) {
      return selectedValue ? item.object_id == selectedValue.id : false;
    },
    selectTypeAncestralPlace(value) {
      this.selectType(
        {address_en: value.name, full_address_en: value.name, id: value.object_id},
        value.selectField,
        value.deselectFields
      );
    },
    deSelectTypeAncestralPlace(value) {
      if (value.selectField) {
        this.deSelectType(value.selectField);
      } else {
        this.selectType(null, null, ['ancestral_level_3', 'ancestral_level_2', 'ancestral_level_1']);
      }
    },
  },
  components: {FilterItem},
};
</script>

<style lang="scss" scoped></style>
