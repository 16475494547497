<template>
  <div class="filter-item" v-if="options.length">
    <filter-item-value
      class="filter-label"
      :item="headerItem"
      :show-count="true"
      @select="resetFilter"
    ></filter-item-value>

    <ul class="filter-list">
      <filter-item-value
        v-for="item in shownOptions"
        :key="item.object_id || item.name"
        :item="item"
        @select="onItemClick"
      ></filter-item-value>

      <li v-if="canExpand" class="expand-toggle">
        <a @click="toggleExpand" v-if="isExpanded">Show Less &#9652;</a>
        <a @click="toggleExpand" v-else>Show More &#9662;</a>
      </li>
    </ul>
  </div>
</template>

<script>
import FilterItemValue from '@common/pages/searches/filters/FilterItemValue';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';

export default {
  props: {
    label: String,
    options: Array,
    cutCount: Number,
    forceCut: Boolean,
    totalCount: {type: Number, required: false},
    hideHeaderCount: {type: Boolean, default: false},
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    headerItem() {
      return {name: this.label, count: this.displayTotalCount, isSelected: !this.hasSelectedOption, isHeaderItem: true};
    },
    hasSelectedOption() {
      return !!this.selectedOption;
    },
    selectedOption() {
      for (let option of this.options) {
        if (this.isItemOrChildSelected(option)) {
          return option;
        }
      }
    },
    shownOptions() {
      if (this.isExpanded) {
        return this.options;
      }
      return this.mustCut ? this.getSliceOfOptions() : this.options;
    },
    canExpand() {
      return this.mustCut && this.options.length > this.cutCount;
    },
    mustCut() {
      return this.hasSelectedOption || this.forceCut;
    },
    displayTotalCount() {
      if (this.hideHeaderCount) {
        return null;
      }
      if (this.totalCount) {
        return this.totalCount;
      }
      return this.options.reduce((accumulator, currVal) => {
        const currCount = currVal.ignoreCount ? 0 : currVal.count;
        return accumulator + currCount;
      }, 0);
    },
  },
  methods: {
    onItemClick(item) {
      this.isExpanded = false;
      return this.isSelected(item) ? this.deselect(item) : this.select(item);
    },
    isSelected(item) {
      return item.isSelected;
    },
    resetFilter() {
      if (this.hasSelectedOption) {
        this.deselect(this.headerItem);
      }
    },
    deselect(item) {
      this.$emit('deselect', item);
      this.trackClick(item);
    },
    select(item) {
      this.$emit('select', item);
      this.trackClick(item);
    },
    trackClick(item) {
      AnalyticsAmplitudeHandler.trackSearchFilterClick(
        this.label,
        item.name,
        item.count,
        getRoutePageName(this.$route)
      );
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
    isItemOrChildSelected(item) {
      if (this.isSelected(item)) {
        return true;
      }
      if (!item.children || !item.children.length) {
        return false;
      }
      for (let child of item.children) {
        if (this.isItemOrChildSelected(child)) {
          return true;
        }
      }
      return false;
    },
    getSliceOfOptions() {
      const slice = this.options.slice(0, this.cutCount);
      if (this.hasSelectedOption && !slice.includes(this.selectedOption)) {
        return [this.selectedOption, ...slice];
      }
      return slice;
    },
  },
  components: {FilterItemValue},
  name: 'FilterItem',
};
</script>

<style lang="scss" scoped>
.filter-item {
  margin-bottom: 20px;

  .filter-label {
    display: block;
  }

  .filter-list {
    margin: 0;
    list-style-type: none;
    padding-inline-start: 20px;

    &::v-deep .filter-value {
      padding-top: 0;
      padding-bottom: 0;
    }

    &::v-deep .filter-value.child.level-1 {
      padding-left: 30px;
    }
    &::v-deep .filter-value.child.level-2 {
      padding-left: 20px;
    }
    &::v-deep .filter-value.child.level-3 {
      padding-left: 20px;
    }
  }
  .expand-toggle a {
    color: $text-alternate-color;
  }
}
</style>
