<template>
  <div class="search-records-results">
    <div class="search-results">
      <relaxed-search-disclaimer
        :meta="meta"
        :first-name="filters.first_name"
        :last-name="filters.surname"
      ></relaxed-search-disclaimer>
      <base-pagination-meta :meta="meta"></base-pagination-meta>
      <indexed-record-item v-for="item in list" :key="item.id" :item="item" :options="options"></indexed-record-item>
    </div>
    <base-pagination :meta="meta" @onSwitchPage="onSwitchPage"></base-pagination>
  </div>
</template>

<script>
import BasePagination from '@common/elements/base-pagination';
import BasePaginationMeta from '@common/elements/base-pagination-meta';
import IndexedRecordItem from '@common/pages/searches/IndexedRecordItem';
import RelaxedSearchDisclaimer from '@common/pages/searches/RelaxedSearchDisclaimer';

export default {
  props: {
    filters: Object,
    meta: Object,
    options: Object,
    list: Array,
  },
  computed: {
    // todo: scrapped this logic for now
    // isWholeListExactMatches() {
    //   return this.meta.offset + this.meta.limit <= this.meta.exact_total_count;
    // },
    // listExactMatchesBreakdownIndex() {
    //   const maxOnPage = this.meta.offset + this.meta.limit;
    //   const indexOnPage = this.meta.exact_total_count - this.meta.offset;
    //   return maxOnPage > this.meta.exact_total_count && indexOnPage < this.meta.limit ? indexOnPage : -1;
    // },
    // exactMatchesList() {
    //   if (this.isWholeListExactMatches) {
    //     return this.list;
    //   }
    //   if (this.listExactMatchesBreakdownIndex !== -1) {
    //     return this.list.slice(0, this.listExactMatchesBreakdownIndex);
    //   }
    //   return [];
    // },
    // otherList() {
    //   if (this.isWholeListExactMatches) {
    //     return [];
    //   }
    //   if (this.listExactMatchesBreakdownIndex !== -1) {
    //     return this.list.slice(this.listExactMatchesBreakdownIndex);
    //   }
    //   return this.list;
    // },
  },
  methods: {
    onSwitchPage(page) {
      this.$emit('switch-page', page);
    },
  },
  components: {IndexedRecordItem, BasePagination, BasePaginationMeta, RelaxedSearchDisclaimer},
  name: 'IndexedRecordsResults',
};
</script>

<style scoped lang="scss"></style>
