export default {
  categoriesOptions() {
    if (!this.facets.category_id) {
      return [];
    }
    const allOptions = this.$store.getters.searchAllRecordsOptionsState;

    let options = Object.values(this.facets.category_id);
    const formCategoryId = this.formData.category_id;
    if (formCategoryId && !options.find(item => item.id === formCategoryId)) {
      const categories = allOptions && allOptions.categories ? allOptions.categories : [];
      const option = categories.find(c => c.id === formCategoryId) || {
        name: formCategoryId,
        explicit_name: formCategoryId,
        id: formCategoryId,
      };
      options = [{...option, count: 0, source_types: []}, ...options];
    }
    const sourceTypeOptions = this.facets.source_type;
    const formSourceType = this.formData[this.sourceTypeTypeName];

    let sourceTypeParentFound = false;
    for (let option of options) {
      const isSourceTypeParent = option.source_types.includes(formSourceType);
      if (option.id === this.formData.category_id || isSourceTypeParent) {
        option.children = sourceTypeOptions
          .filter(item => option.source_types.includes(item.object_id))
          .map(item => ({...item, isSelected: item.object_id === formSourceType}));
        sourceTypeParentFound = sourceTypeParentFound || isSourceTypeParent;
        break;
      }
      option.children = [];
    }

    if (!sourceTypeParentFound && formSourceType) {
      const sourceTypes = allOptions && allOptions.source_types ? allOptions.source_types : [];
      const option = sourceTypes.find(st => st.value === formSourceType) || {
        name: formSourceType,
        value: formSourceType,
      };
      options = [{...option, object_id: option.value, count: 0, isSelected: true}, ...options];
    }

    return this.normalizeOptions(options, 'category_id', 'id', 'priority', 'asc');
  },
};
