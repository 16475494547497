<template>
  <li class="filter-value" :class="classes">
    <div :class="{active: item.isSelected}" class="filter-label" @click="onItemClick">
      <a>{{ item.name }}</a>
      <span class="count">{{ itemCountDisplay }}</span>
    </div>

    <filter-item-value
      v-for="child in item.children || []"
      :key="child.object_id || child.name"
      :item="child"
      :level="level + 1"
      @select="onChildSelect"
    ></filter-item-value>
  </li>
</template>

<script>
export default {
  props: {
    item: Object,
    level: {type: Number, default: 0},
    isActive: Boolean,
  },
  computed: {
    classes() {
      return this.level > 0 ? `child level-${this.level}` : '';
    },
    itemCountDisplay() {
      return this.item.count || this.item.count === 0 ? `(${this.item.count})` : '';
    },
  },
  methods: {
    onItemClick() {
      const args = {...this.item, level: this.level};
      this.$emit('select', args);
    },
    onChildSelect(data) {
      this.$emit('select', data);
    },
  },
  name: 'FilterItemValue',
};
</script>

<style lang="scss" scoped>
.filter-value {
  .filter-label {
    padding: 7px 0;

    &.active {
      font-weight: bold;
    }
  }
  .count {
    color: $supplemental-text-color;
    margin-left: 5px;
  }
}
</style>
